import React, { useState, useEffect, useRef, useCallback } from 'react';
import FileList from './components/FileList';
import PlayList from './components/PlayList';
import LandingPage from './LandingPage';
import './styles/GlobalStyles.css';
import './App.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const API_URL = process.env.REACT_APP_API_URL || '/api';  
  const [files, setFiles] = useState([]);
  const [playingFiles, setPlayingFiles] = useState({});
  const [activeTab, setActiveTab] = useState('play');
  const [fileColors, setFileColors] = useState({});
  const [fileTags, setFileTags] = useState({});
  const audioRefs = useRef({});  
  const [userId, setUserId] = useState('user');
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [user, setUser] = useState(null);

  const G_userEmail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).email : 'guest';
  const G_username = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).name : 'guestid';


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get('userid');
    if (userIdParam === 'admin' || userIdParam === 'user') {
      setUserId(userIdParam);
    }
  }, []);

  const cleanupAudio = useCallback(() => {
    Object.values(audioRefs.current).forEach(audio => {
      audio.pause();
      audio.src = '';
    });
  }, []);

  const fetchFiles = useCallback(async () => {
    try {
      //console.log('Fetching files from:', `${API_URL}/files`);
      const response = await fetch(`${API_URL}/files`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      //console.log('Fetched files:', data);
      setFiles(data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [API_URL]);

  const fetchTags = useCallback(async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not found');
      }
      const response = await fetch(`${API_URL}/tags/${user.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch tags');
      }
      const data = await response.json();
      setFileTags(data);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setFileTags({});
    }
  }, [API_URL]);

  const fetchColors = useCallback(async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not found');
      }
      const response = await fetch(`${API_URL}/colors/${user.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch colors');
      }
      const data = await response.json();
      setFileColors(data.fileColors);
    } catch (error) {
      console.error('Error fetching colors:', error);
      setFileColors({});
    }
  }, [API_URL]);

  useEffect(() => {
    fetchFiles();
    loadFileColors();
    fetchTags();
    fetchColors();

    return cleanupAudio;
  }, [cleanupAudio, fetchFiles, fetchTags, fetchColors]);

  const loadFileColors = () => {
    const savedColors = localStorage.getItem('fileColors');
    if (savedColors) {
      setFileColors(JSON.parse(savedColors));
    }
  };

  const saveFileColors = (newColors) => {
    localStorage.setItem('fileColors', JSON.stringify(newColors));
  };

  const handleDelete = async (filename) => {
    try {
      const response = await fetch(`${API_URL}/files/${encodeURIComponent(filename)}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setFiles(files.filter(file => file.name !== filename));
        setPlayingFiles(prevPlayingFiles => {
          const newPlayingFiles = { ...prevPlayingFiles };
          delete newPlayingFiles[filename];
          return newPlayingFiles;
        });
        const newColors = { ...fileColors };
        delete newColors[filename];
        setFileColors(newColors);
        saveFileColors(newColors);
        const newTags = { ...fileTags };
        delete newTags[filename];
        setFileTags(newTags);
      } else {
        console.error('Error deleting file');
        alert('Error deleting file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Error deleting file');
    }
  };

  const handleRename = async (oldFilename, newFilename) => {
    try {
      const response = await fetch(`${API_URL}/files/${encodeURIComponent(oldFilename)}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newName: newFilename }),
      });
      if (response.ok) {
        setFiles(files.map(file => 
          file.name === oldFilename ? { ...file, name: newFilename } : file
        ));
        setPlayingFiles(prevPlayingFiles => {
          const newPlayingFiles = { ...prevPlayingFiles };
          if (newPlayingFiles[oldFilename] !== undefined) {
            newPlayingFiles[newFilename] = newPlayingFiles[oldFilename];
            delete newPlayingFiles[oldFilename];
          }
          return newPlayingFiles;
        });
        const newColors = { ...fileColors };
        if (newColors[oldFilename]) {
          newColors[newFilename] = newColors[oldFilename];
          delete newColors[oldFilename];
          setFileColors(newColors);
          saveFileColors(newColors);
        }
        const newTags = { ...fileTags };
        if (newTags[oldFilename]) {
          newTags[newFilename] = newTags[oldFilename];
          delete newTags[oldFilename];
          setFileTags(newTags);
        }
      } else {
        console.error('Error renaming file');
        alert('Error renaming file');
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      alert('Error renaming file');
    }
  };

  const handlePlay = async (file) => {
    try {
      // 假設文件名就是 R2 中的對象鍵
      const audioUrl = `https://streambase.niugpt.com/${encodeURIComponent(file.name)}.mp3`;
      //console.log('audioUrl:', audioUrl);

      // 停止所有其他正在播放的音檔
      Object.entries(audioRefs.current).forEach(([fileName, audio]) => {
        if (fileName !== file.name) {
          audio.pause();
          audio.currentTime = 0;
          setPlayingFiles(prevPlayingFiles => ({ ...prevPlayingFiles, [fileName]: false }));
        }
      });

      // 獲取或創建當前文件的音頻元素
      if (!audioRefs.current[file.name]) {
        audioRefs.current[file.name] = new Audio();
      }
      const audio = audioRefs.current[file.name];
      
      audio.src = audioUrl;
      
      try {
        await audio.play();
        setPlayingFiles(prevPlayingFiles => ({ ...prevPlayingFiles, [file.name]: true }));

        // 添加額外的檢查
        audio.onended = () => {
          setPlayingFiles(prevPlayingFiles => ({ ...prevPlayingFiles, [file.name]: false }));
        };

        // 記錄播放信息
        const device = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent) ? 'mobile' : 'desktop';
        const token = localStorage.getItem('accessToken');
        await fetch(`${API_URL}/log-play`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // 添加認證 token
          },
          body: JSON.stringify({
            fileName: file.name,
            device
          }),
        });
      } catch (playError) {
        console.error('Error playing audio:', playError);
        setPlayingFiles(prevPlayingFiles => ({ ...prevPlayingFiles, [file.name]: false }));
      }
    } catch (error) {
      console.error('Error handling play:', error);
      alert('Error playing file');
    }
  };

  const handleColorChange = async (filename, color) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not found');
      }
      const newColors = { ...fileColors, [filename]: color };
      const response = await fetch(`${API_URL}/colors/${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileColors: newColors }),
      });
      if (response.ok) {
        setFileColors(newColors);
      } else {
        console.error('Error updating colors');
        alert('Error updating colors');
      }
    } catch (error) {
      console.error('Error updating colors:', error);
      alert('Error updating colors');
    }
  };

  const handleTagChange = async (filename, tags) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not found');
      }
      const response = await fetch(`${API_URL}/tags/${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName: filename, tags }),
      });
      if (response.ok) {
        fetchTags();
      } else {
        console.error('Error updating tags');
        alert('Error updating tags');
      }
    } catch (error) {
      console.error('Error updating tags:', error);
      alert('Error updating tags');
    }
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${API_URL}/upload`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        setFiles(prevFiles => [...prevFiles, data]);
        // 可需要更新其他狀態，如 fileColors 或 fileTags
      } else {
        console.error('Error uploading file:', await response.text());
        alert('上傳音效文件失敗');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('上傳音效文件失敗');
    }
  }; 

  const handleLoginLog = async (userInfo) => {
    try {
      const response = await fetch(`${API_URL}/loginLog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },        
        body: JSON.stringify({ userInfo: userInfo }),
      });
      if (!response.ok) {
        throw new Error('使用localStorage user資料寫入日誌失敗A');
      }
    } catch (error) {
      console.error('使用localStorage user資料寫入日誌時發生錯誤A:', error);
    }
  };

  const handleEnterApp = (userInfo) => {
    setShowLandingPage(false);
    setUser(userInfo);
    //console.log('用戶登入:', userInfo);
    localStorage.setItem('user', JSON.stringify(userInfo));
    handleLoginLog(userInfo);
    fetchTags();
    fetchColors();
  };

  if (showLandingPage) {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <LandingPage onEnterApp={handleEnterApp} />
      </GoogleOAuthProvider>
    );
  }

  return (
    <div className="container">
      <div className="gradient-title">
        <div className="avatar"></div>
        <span>💊小毒藥JJ🎙️ 音效播放器 ver:1.1</span>
      </div>
      {user && (
        <div className="user-info">
          {/* <img src={user.picture} alt={user.name} className="user-avatar" /> */}
          <span>Welcome, {user.name}~</span>
        </div>
      )}      
      <div className="tab-container">
        <button 
          className={`tab-button ${activeTab === 'play' ? 'active' : ''}`} 
          onClick={() => setActiveTab('play')}
        >
          音效播放區
        </button>
        <button 
          className={`tab-button ${activeTab === 'manage' ? 'active' : ''}`} 
          onClick={() => setActiveTab('manage')}
        >
          音效管理區
        </button>
      </div>
      {activeTab === 'play' ? (
        <PlayList 
          files={files} 
          onPlay={handlePlay}
          playingFiles={playingFiles}
          setPlayingFiles={setPlayingFiles}
          fileColors={fileColors}
          audioRefs={audioRefs}
          fileTags={fileTags}
          userId={userId}
          API_URL={API_URL}
          G_userEmail={G_userEmail}
          G_username={G_username}
        />
      ) : (
        <FileList 
          files={files} 
          onDelete={handleDelete} 
          onRename={handleRename}
          onColorChange={handleColorChange}
          onTagChange={handleTagChange}
          fileColors={fileColors}
          fileTags={fileTags}
          onFileUpload={handleFileUpload}
          userId={userId}
          G_userEmail={G_userEmail}
        />
      )}
    </div>
  );
}

export default App;