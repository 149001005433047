import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FaPlay, FaChartBar, FaCalendarAlt, FaUser, FaMobileAlt } from 'react-icons/fa';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DatePicker from 'react-datepicker';
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import './PlayList.css';

// 引入熱力圖模組
import HighchartsHeatmap from 'highcharts/modules/heatmap';
HighchartsHeatmap(Highcharts);

function PlayList({ files, onPlay, playingFiles, setPlayingFiles, fileColors, audioRefs, fileTags, API_URL, userId, G_userEmail, G_username }) {
  const setPlayingFilesRef = useRef(setPlayingFiles);
  const [showStats, setShowStats] = useState(false);
  const [playStats, setPlayStats] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [chartType, setChartType] = useState('heatmap');
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    setPlayingFilesRef.current = setPlayingFiles;
  }, [setPlayingFiles]);

  const handleEnded = useCallback((fileName) => {
    setPlayingFilesRef.current(prevPlayingFiles => ({ ...prevPlayingFiles, [fileName]: false }));
  }, []);

  useEffect(() => {
    const currentAudioRefs = audioRefs.current;
    const endedListeners = new Map();

    files.forEach(file => {
      const audio = currentAudioRefs[file.name];
      if (audio) {
        const listener = () => handleEnded(file.name);
        endedListeners.set(file.name, listener);
        audio.addEventListener('ended', listener);
      }
    });

    return () => {
      endedListeners.forEach((listener, fileName) => {
        const audio = currentAudioRefs[fileName];
        if (audio) {
          audio.removeEventListener('ended', listener);
        }
      });
    };
  }, [files, audioRefs, handleEnded]);

  const fetchPlayStats = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/play-stats`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      //console.log('Received play stats:', data);
      setPlayStats(data);
    } catch (error) {
      console.error('Error fetching play stats:', error);
      alert('Error fetching play stats: ' + error.message);
    }
  }, [API_URL]);

  const sortedFileGroups = useMemo(() => {
    const groups = {};
    files.forEach(file => {
      const tags = fileTags[file.name] || ['未分類'];
      tags.forEach(tag => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(file);
      });
    });
    return Object.entries(groups).sort((a, b) => a[0].localeCompare(b[0], 'zh-TW'));
  }, [files, fileTags]);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const generateChartOptions = useCallback((stats) => {
    //console.log('Generating chart options:', { stats, startDate, endDate, chartType });
    const userStats = stats.userDetails[G_userEmail];
    if (!userStats || !userStats.playsByHour) {
      console.log('No user stats available');
      return null;
    }

    const days = eachDayOfInterval({ start: startDate, end: endDate }).map(date => format(date, 'yyyy-MM-dd'));
    const hours = Array.from({length: 24}, (_, i) => i);

    const data = days.flatMap((day, dayIndex) => 
      hours.map(hour => {
        const fullTime = `${day} ${String(hour).padStart(2, '0')}:00`;
        const playCount = userStats.playsByHour.find(([time]) => time === fullTime)?.[1] || 0;
        return [dayIndex, hour, playCount];
      })
    );

    const seriesData = {
      heatmap: [{
        name: '播放次數',
        borderWidth: 1,
        data: data,
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      }],
      line: [{
        name: '每日播放次數',
        data: days.map(day => 
          userStats.playsByHour.filter(([time]) => time.startsWith(day))
            .reduce((sum, [_, count]) => sum + count, 0)
        )
      }],
      bar: [{
        name: '每日播放次數',
        data: days.map(day => 
          userStats.playsByHour.filter(([time]) => time.startsWith(day))
            .reduce((sum, [_, count]) => sum + count, 0)
        )
      }]
    };

    const commonOptions = {
      title: {
        text: '播放趨勢'
      },
      xAxis: {
        categories: days,
        labels: {
          rotation: -45
        }
      },
      yAxis: {
        title: {
          text: '播放次數'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shared: true
      },
      accessibility: {
        enabled: false
      }      
    };

    const specificOptions = {
      heatmap: {
        chart: { type: 'heatmap' },
        yAxis: {
          categories: hours,
          title: null,
          reversed: true
        },
        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          maxColor: Highcharts.getOptions().colors[0]
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.series.xAxis.categories[this.point.x]}</b> ${this.point.y}:00<br>
                    <b>${this.point.value}</b> 次播放`;
          }
        }
      },
      line: {
        chart: { type: 'line' }
      },
      bar: {
        chart: { type: 'column' }
      }
    };

    const options = {
      ...commonOptions,
      ...specificOptions[chartType],
      series: seriesData[chartType]
    };

    //console.log('Generated chart options:', options);
    return options;
  }, [startDate, endDate, chartType, G_userEmail]);

  useEffect(() => {
    if (playStats) {
      const options = generateChartOptions(playStats);
      setChartOptions(options);
    }
  }, [playStats, generateChartOptions]);

  const renderPlayStats = () => {
    if (!playStats) return null;

    return (
      <div className="play-stats">
        <h3>播放統計</h3>
        <div className="stat-section">
          <FaPlay /> 總播放次數: <strong>{formatNumber(playStats.totalPlays)}</strong>
        </div>

        <div className="stat-section">
          <h4><FaCalendarAlt /> 熱門文件 (前5名)</h4>
          <ul>
            {Object.entries(playStats.playsByFileAndHour)
              .flatMap(([_, files]) => Object.entries(files))
              .sort((a, b) => b[1] - a[1])
              .slice(0, 5)
              .map(([fileName, count], index) => (
                <li key={fileName}>
                  <span className="rank">{index + 1}</span>
                  {fileName}: <strong>{formatNumber(count)}</strong> 次播放
                </li>
              ))}
          </ul>
        </div>

        <div className="stat-section">
          <h4><FaUser /> 活躍用戶 (前5名)</h4>
          <ul>
            {Object.entries(playStats.playsByUser)
              .sort((a, b) => b[1] - a[1])
              .slice(0, 5)
              .map(([user, count], index) => (
                <li key={user}>
                  <span className="rank">{index + 1}</span>
                  {user}: <strong>{formatNumber(count)}</strong> 次播放
                </li>
              ))}
          </ul>
        </div>

        <div className="stat-section">
          <h4><FaMobileAlt /> 設備使用情況</h4>
          <ul>
            {Object.entries(playStats.playsByDevice)
              .sort((a, b) => b[1] - a[1])
              .map(([device, count]) => (
                <li key={device}>
                  {device}: <strong>{formatNumber(count)}</strong> 次播放
                </li>
              ))}
          </ul>
        </div>

        <div className="stat-section user-details">
          <h4><FaUser /> 用戶詳細統計</h4>
          <div className="chart-controls">
            <div className="date-picker">
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
            <div className="chart-type-selector">
              <button onClick={() => setChartType('heatmap')}>熱力圖</button>
              <button onClick={() => setChartType('line')}>折線圖</button>
              <button onClick={() => setChartType('bar')}>柱狀圖</button>
            </div>
          </div>
          {chartOptions ? (
            <div style={{ width: '100%', height: '400px' }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
              />
            </div>
          ) : (
            <p>無法生成圖表。請檢查數據是否正確。</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="play-list">
      {G_userEmail === 'charlielei.ee@gmail.com' && (
        <button onClick={() => { setShowStats(!showStats); if (!showStats) fetchPlayStats(); }} className="stats-button">
          <FaChartBar /> {showStats ? '隱藏統計' : '查看播放統計'}
        </button> 
      )}
      
      {showStats && playStats && G_userEmail === 'charlielei.ee@gmail.com' && renderPlayStats()}

      {sortedFileGroups.map(([tag, groupFiles]) => (
        <div key={tag} className="play-list-tag-group">
          <h3 className="play-list-tag-title">{tag}</h3>
          <div className="play-list-file-grid">
            {groupFiles.map((file, index) => (
              <div 
                key={index} 
                className={`play-list-file-item ${playingFiles[file.name] ? 'playing' : ''}`}
                onClick={() => onPlay(file)}
                style={{
                  backgroundColor: fileColors[file.name] || '#003E3E',
                  border: `1px solid ${fileColors[file.name] || '#003E3E'}`,
                }}
              >
                <div className="play-list-file-name">{file.name}</div>
                {playingFiles[file.name] && (
                  <div className="play-list-playing-indicator">
                    <FaPlay />
                    正在播放
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlayList;