import React, { useState, useMemo } from 'react';
import './FileList.css';

function FileList({ files, onDelete, onRename, onColorChange, onTagChange, fileColors, fileTags, onFileUpload, userId, G_userEmail }) {
  const [isRenaming, setIsRenaming] = useState(false);
  const [renamingFile, setRenamingFile] = useState(null);
  const [newName, setNewName] = useState('');
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [editingTagsFile, setEditingTagsFile] = useState(null);
  const [newTags, setNewTags] = useState('');
  const isAdmin = G_userEmail === 'charlielei.ee@gmail.com';

  //console.log('G_userEmail:', G_userEmail);
  //console.log('isAdmin:', isAdmin);

  const handleStartRename = (file) => {
    if (!isAdmin) {
      //console.error('Permission denied: Cannot rename file');
      return;
    }
    setIsRenaming(true);
    setRenamingFile(file);
    setNewName(file.name);
  };

  const handleConfirmRename = () => {
    if (newName.trim() === '') {
      alert('文件名不能為空');
      return;
    }
    if (files.some(file => file.name === newName && file !== renamingFile)) {
      alert('文件名已存在');
      return;
    }
    onRename(renamingFile.name, newName);
    setIsRenaming(false);
    setRenamingFile(null);
    setNewName('');
  };

  const handleCancelRename = () => {
    setIsRenaming(false);
    setRenamingFile(null);
    setNewName('');
  };

  const handleStartEditTags = (file) => {
    if (userId === 'none') {
      //console.error('Permission denied: Cannot edit tags');
      return;
    }
    setIsEditingTags(true);
    setEditingTagsFile(file);
    setNewTags(fileTags[file.name] ? fileTags[file.name].join(', ') : '');
  };

  const handleConfirmEditTags = () => {
    const tagsArray = newTags.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
    onTagChange(editingTagsFile.name, tagsArray);
    setIsEditingTags(false);
    setEditingTagsFile(null);
    setNewTags('');
  };

  const handleCancelEditTags = () => {
    setIsEditingTags(false);
    setEditingTagsFile(null);
    setNewTags('');
  };

  const handleFileUpload = (event) => {
    if (!isAdmin) {
      //console.error('Permission denied: Cannot upload file');
      return;
    }
    const file = event.target.files[0];
    if (file) {
      onFileUpload(file);
    }
  };

  // 使用 useMemo 來優化性能，只有當 files 或 fileTags 改變時才重新計算
  const sortedFileGroups = useMemo(() => {
    // 創建一個對象來存儲分組後的檔案
    const groups = {};

    // 遍歷所有檔案
    files.forEach(file => {
      const tags = fileTags[file.name] || ['未分類'];
      
      // 對每個標籤進行處理
      tags.forEach(tag => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(file);
      });
    });

    // 將分組對象轉換為數組並排序
    return Object.entries(groups).sort((a, b) => a[0].localeCompare(b[0], 'zh-TW'));
  }, [files, fileTags]);

  return (
    <div className="file-list-container">            
      <div className="file-list-upload-section">
        {isAdmin && (
          <>
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".mp3,.wav"
              id="file-upload"
              className="file-list-upload-input"
            />
            <label htmlFor="file-upload" className="file-list-upload-label">
              上傳新音效
            </label>
          </>
        )}
      </div>
      {sortedFileGroups.map(([tag, groupFiles]) => (
        <div key={tag} className="file-list-tag-group">
          <h3 className="file-list-tag-title">{tag}</h3>
          {groupFiles.map((file, index) => (
            <div key={index} className="file-list-item">
              <div className="file-list-info">
                <span className="file-list-name">{file.name}</span>
                {fileTags[file.name] && fileTags[file.name].length > 0 && (
                  <button className="file-list-tags">
                    標籤:{fileTags[file.name].join(', ')}
                  </button>
                )}
              </div>
              <div className="file-list-actions">
                {isAdmin && (
                  <button 
                    onClick={() => handleStartRename(file)} 
                    className="file-list-btn-action file-list-btn-rename"
                    aria-label={`重命名 ${file.name}`}
                  >
                    重命名
                  </button>
                )}
                <button onClick={() => handleStartEditTags(file)} className="file-list-btn-action file-list-btn-tag">
                  設定標籤
                </button>
                {isAdmin && (
                  <button onClick={() => {
                    if (window.confirm(`確定要刪除嗎？`)) {
                      onDelete(file.name);
                    }
                  }} className="file-list-btn-action file-list-btn-delete">
                    刪　除
                  </button>
                )}
                <div className="file-list-color-picker-container">
                  <input
                    type="color"
                    value={fileColors[file.name] || '#003E3E'}
                    onChange={(e) => onColorChange(file.name, e.target.value)}
                    className="file-list-color-picker"
                    title="更改顏色"
                  />
                </div>
              </div>
              {isRenaming && renamingFile === file && (
                <div className="file-list-rename-dialog">
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="file-list-rename-input"
                    placeholder="輸入新的檔案名稱"
                    aria-label="新檔案名稱"
                  />
                  <button onClick={handleCancelRename} className="file-list-rename-button file-list-rename-cancel">
                    取消
                  </button>
                  <button onClick={handleConfirmRename} className="file-list-rename-button file-list-rename-confirm">
                    確認
                  </button>
                </div>
              )}
              {isEditingTags && editingTagsFile === file && (
                <div className="file-list-edit-tags-dialog">
                  <input
                    type="text"
                    value={newTags}
                    onChange={(e) => setNewTags(e.target.value)}
                    className="file-list-tags-input"
                    placeholder="輸入標籤，用逗號分隔"
                  />
                  <button onClick={handleCancelEditTags} className="file-list-tags-button file-list-tags-cancel">
                    取消
                  </button>
                  <button onClick={handleConfirmEditTags} className="file-list-tags-button file-list-tags-confirm">
                    確認
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default FileList;