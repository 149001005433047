import React, { useState, useEffect } from 'react';
import { Music, Users, Zap, ArrowRight, PenTool, X, Globe } from 'lucide-react';
import { GoogleLogin } from '@react-oauth/google';

const LandingPage = ({ onEnterApp }) => {
  const [selectedTool, setSelectedTool] = useState(null);
  const [language, setLanguage] = useState('en');  
  const fileColors = localStorage.getItem('fileColors') ? JSON.parse(localStorage.getItem('fileColors')) : '';
  const G_userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : '';
  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    setLanguage(userLang.startsWith('zh') ? 'zh' : 'en');
  }, []);

  //console.log('fileColors:', fileColors);
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log('fileColorsG:', fileColors);      
      // 發送 Google ID token 到後端進行驗證
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: credentialResponse.credential, fileColors: fileColors }),
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate with the server');
      }

      const data = await response.json();
      //console.log('LandingPage data:', data);
      
      // 假設後端返回的數據包含 user 信息和 accessToken
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('accessToken', data.accessToken);

      onEnterApp(data.user);
    } catch (error) {
      console.error('Authentication error:', error);
      alert('登錄失敗，請稍後再試。');
    }
  };

  const handleGoogleFailure = () => {
    console.error('Google Sign-In Failed');
    // 處理登錄失敗，可能顯示錯誤消息
  };

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'en' ? 'zh' : 'en');
  };

  const handleEnterApp = () => {
    const user = localStorage.getItem('user');
    const accessToken = localStorage.getItem('accessToken');

    if (user && accessToken) {
      console.log('Entering main application');
      onEnterApp(JSON.parse(user));
    } else {
      //setShowGoogleLogin(true);
    }
  };

  const content = {
    en: {
      nav: { features: "Features", pricing: "Pricing" },
      header: { title: "💊Unleash Your Potential💊", subtitle: "NiuGPT's Intelligent Application Platform", productName: "StreamPro" },
      cta: { tryNow: "Start Free Trial", startJourney: "Start Your StreamPro Journey" },
      signup: { title: "Sign Up for StreamPro", button: "Sign Up with Google", description: "Quick and secure to access all StreamPro features" },
      footer: { rights: "All rights reserved" },
      tools: [
        {
          icon: <Music className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "Live Audio Effects",
          description: "Rich sound library and real-time audio processing for immersive streaming",
          details: "Our Live Audio Effects tool provides professional-grade audio processing capabilities. It includes thousands of preset sound effects, from ambient sounds to voice changers. Real-time audio processing technology allows you to adjust audio quality instantly, ensuring the best listening experience. Additionally, AI-driven noise cancellation effectively filters background noise, making your voice clearer and more appealing.",
        },
        {
          icon: <PenTool className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "Post Creation Wizard",
          description: "Smart templates and editing tools for eye-catching posts",
          details: "The Post Creation Wizard makes content creation simple and fun. We offer hundreds of optimized templates covering various themes and styles. Smart layout features automatically adjust text and image layouts to ensure visual appeal. The built-in AI copywriting assistant can generate engaging titles and copy based on your keywords. Moreover, one-click multi-platform publishing allows your creative content to reach all social media instantly.",
        },
        {
          icon: <Users className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "Interaction Tools",
          description: "Real-time chat, boost audience engagement, drive streaming popularity",
          details: "Our interactive toolkit equips your live stream chat with a 24/7 AI-powered smart assistant, significantly enhancing the audience interaction experience. This little helper can analyze chat content in real-time, assist in managing the discussion atmosphere, and add fun and interactivity to your live streams.",
        },
        {
          icon: <Zap className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "Smart Analytics",
          description: "Deep insights into your audience and content performance",
          details: "Smart Analytics tools provide data support for your content strategy. Real-time audience analysis can show the geographic distribution, age groups, and interests of your audience. The content performance tracking system lets you clearly understand which types of content are most popular. Predictive analytics features can recommend the best live streaming times and content topics. Through visualized charts, you can easily understand complex data and make informed decisions.",
        }
      ],
    },
    zh: {
      nav: { features: "功能", pricing: "價格" },
      header: { title: "💊 釋放您的潛力 💊", subtitle: "NiuGPT's 智能應用平台", productName: "StreamPro" },
      cta: { tryNow: "立即開始免費試用", startJourney: "開始您的StreamPro之旅" },
      signup: { title: "立即註冊StreamPro", button: "使用Google帳號註冊", description: "快速、安全的方式，開始使用StreamPro的所有功能" },
      footer: { rights: "保留所有權利" },
      tools: [
        {
          icon: <Music className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "直播音效神器",
          description: "豐富的音效庫和實時音頻處理，讓您的直播魅力無限",
          details: "我們的直播音效神器為您提供專業級的音頻處理能力。包含數千種預設音效，從環境音到語音變聲，應有盡有。實時音頻處理技術讓您能夠即時調整音質，確保最佳收聽體驗。此外，AI驅動的噪音消除功能可以有效過濾背景雜音，讓您的聲音更加清晰動聽。",
        },
        {
          icon: <PenTool className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "貼文創作神器",
          description: "智能模板和編輯工具，輕鬆製作吸睛貼文",
          details: "貼文創作神器讓內容製作變得簡單有趣。我們提供數百個經過優化的模板，涵蓋各種主題和風格。智能排版功能可以自動調整文字和圖片布局，確保視覺吸引力。內置的AI文案助手可以根據您的關鍵詞生成吸引人的標題和文案。此外，一鍵式的多平台發布功能讓您的創意內容瞬間觸達所有社交媒體。",
        },
        {
          icon: <Users className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "智能聊天助手",
          description: "實時聊天、增加粉絲互動、帶動直播熱度",
          details: "我們的互動工具套件為您的直播聊天區配備24/7全天候的AI智能聊天助手,大幅提升粉絲互動體驗。這位小幫手能夠實時分析聊天內容,協助管理討論氛圍,並為您的直播增添趣味和互動性。",
        },
        {
          icon: <Zap className="w-12 h-12 mb-4 text-[#FFD306]" />,
          title: "智能分析",
          description: "深入了解您的觀眾和內容表現",
          details: "智能分析工具為您的內容策略提供數據支持。實時觀眾分析可以顯示觀眾地理分布、年齡段和興趣愛好。內容表現追蹤系統讓您清楚了解哪些類型的內容最受歡迎。預測性分析功能可以推薦最佳直播時間和內容主題。通過可視化圖表，您可以輕鬆理解複雜的數據，做出明智的決策。",
        }
      ],
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#003E3E] to-[#006666] text-white overflow-x-hidden">
      <header className="container mx-auto px-4 py-8">
        <nav className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="text-3xl font-bold mr-2">NiuGPT</div>            
          </div>
          <div className="space-x-4 flex items-center">
            <a href="#features" className="hover:text-[#FFD306]">{content[language].nav.features}</a>
            {/* <a href="#pricing" className="hover:text-[#FFD306]">{content[language].nav.pricing}</a> */}
            <button onClick={toggleLanguage} className="flex items-center hover:text-[#FFD306]">
              <Globe className="mr-1 text-[#FFD306]" size={20} />
              {language === 'en' ? '中文' : 'English'}
            </button>
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 text-[#FFD306]">{content[language].header.title}</h1>
          <p className="text-xl mb-1">{content[language].header.subtitle}</p>
          <p className="text-2xl font-semibold mb-4">{content[language].header.productName}</p>          
          {G_userId!=='' && (<button 
            className="bg-[#FFD306] text-[#003E3E] px-8 py-3 rounded-full font-semibold hover:bg-[#E6BE05] transition duration-300 inline-flex items-center"
            onClick={handleEnterApp}
            >
            {content[language].cta.startJourney} <ArrowRight className="ml-2 text-[#003E3E]" />
          </button>
          )}
          {G_userId==='' && (
            <div id="signup" className="max-w-md mx-auto p-4 rounded-lg">            
            <div className="flex justify-end pr-[70px]">
              <GoogleLogin
                // 這些屬性用於配置 Google 登錄按鈕的外觀和行為
                type="standard"        // 設置按鈕類型為標準樣式
                theme="outline"        // 設置按鈕主題為輪廓樣式
                size="large"           // 設置按鈕大小為大號
                text="signin_with"     // 設置按鈕文字為 "使用 Google 登錄"
                shape="pill"           // 設置按鈕形狀為膠囊形
                logo_alignment="left"  // 設置 Google 標誌對齊到左側
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
                useOneTap
              />
            </div>            
          </div>  
)}                  
        </div>

        <div id="features" className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {content[language].tools.map((tool, index) => (
            <div 
              key={index}               
              className="bg-[#004D4D] p-8 rounded-lg text-center cursor-pointer hover:bg-[#005A5A] transition duration-300 shadow-lg hover:shadow-xl border-t-4 border-[#FFD306]"
              onClick={() => setSelectedTool(tool)}
            >
              {React.cloneElement(tool.icon, { className: "w-12 h-12 mb-4 text-[#FFD306]" })}
              <h3 className="text-xl font-semibold mb-2">{tool.title}</h3>
              <p>{tool.description}</p>
            </div>
          ))}
        </div>

        {selectedTool && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-[#004D4D] text-white p-8 rounded-lg max-w-2xl w-full relative">
              <button 
                className="absolute top-4 right-4 text-[#FFD306] hover:text-white"
                onClick={() => setSelectedTool(null)}
              >
                <X size={24} />
              </button>
              <h2 className="text-3xl font-bold mb-4 text-[#FFD306]">{selectedTool.title}</h2>
              <p className="mb-4 text-[#FFD306]">{selectedTool.description}</p>
              <p>{selectedTool.details}</p>
            </div>
          </div>
        )}
        
        <div className="text-center mb-16">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-[#FFD306]">{language === 'en' ? "Ready to elevate your AI experience?" : "準備好提升您的智能體驗了嗎？"}</h2>
        </div>
      </main>

      <footer className="bg-[#002B2B] py-8 mt-16">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 NiuGPT StreamPro. {content[language].footer.rights}</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;